.feedback-main {
    /* background-color: var(--color-1); */
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.feedback-container {
    color: black;
    /* padding: 50px 0px; */
}

.feedback-container>h1 {
    font-size: 4.5rem;
    text-align: center;
}

.form {
    width: 100%;
    border-radius: 10px;
    z-index: 1000;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.contact-form {
    background-color: white;
    position: relative;
}

.circle {
    border-radius: 50%;
    background: linear-gradient(135deg, transparent 20%, var(--color-1));
    position: absolute;
}

.circle.one {
    width: 130px;
    height: 130px;
    top: 130px;
    right: -40px;
}

.circle.two {
    width: 80px;
    height: 80px;
    top: 10px;
    right: 30px;
}

.contact-form:before {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    background-color: white;
    transform: rotate(45deg);
    top: 50px;
    left: -13px;
}

form {
    padding: 2.3rem 2.2rem;
    z-index: 10;
    overflow: hidden;
    position: relative;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.title-1 {
    color: black;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 0.7rem;
}

.title-2 {
    color: var(--color-1);
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 0.7rem;
}

.input-container {
    position: relative;
    margin: 1rem 0;
}

.input {
    width: 100%;
    outline: none;
    border: 2px solid var(--color-2);
    background: none;
    padding: 0.6rem 1.2rem;
    color: var(--color-1);
    font-weight: 500;
    font-size: 0.95rem;
    letter-spacing: 0.5px;
    border-radius: 25px;
    transition: 0.3s;
}

textarea.input {
    padding: 0.8rem 1.2rem;
    min-height: 150px;
    border-radius: 22px;
    resize: none;
    overflow-y: auto;
}

.submit-button {
    padding: 0.6rem 1.3rem;
    background-color: var(--color-1);
    border: 2px solid var(--color-2);
    font-size: 0.95rem;
    color: white;
    line-height: 1;
    border-radius: 25px;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    margin: 0;
}

.submit-button:hover {
    background-color: var(--color-2);
    color: #fff;
}

.input-container span {
    position: absolute;
    top: 0;
    left: 25px;
    transform: translateY(-50%);
    font-size: 0.8rem;
    padding: 0 0.4rem;
    color: transparent;
    pointer-events: none;
    z-index: 500;
}

.input-container span:before,
.input-container span:after {
    content: "";
    position: absolute;
    width: 10%;
    opacity: 0;
    transition: 0.3s;
    height: 5px;
    background-color: var(--color-2);
    top: 50%;
    transform: translateY(-50%);
}

.input-container span:before {
    left: 50%;
}

.input-container span:after {
    right: 50%;
}

.input-container.focus label {
    top: 0;
    transform: translateY(-50%);
    left: 25px;
    font-size: 0.8rem;
}

.input-container.focus span:before,
.input-container.focus span:after {
    width: 50%;
    opacity: 1;
}

.contact-info {
    padding: 2.3rem 2.2rem;
    position: relative;
}

.text {
    margin: 1.5rem 0 2rem 0;
}

.information {
    display: flex;
    margin: 0.7rem 0;
    align-items: center;
    font-size: 0.95rem;
    margin-bottom: 1rem;
}

.information>p {
    margin-bottom: 0px;
}

.icon {
    width: 28px;
    margin-right: 0.7rem;
}

.social-media {
    padding: 2rem 0 0 0;
}

.social-icons {
    display: flex;
    margin-top: 0.5rem;
}

.social-icons a {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    /* background: linear-gradient(45deg, var(--color-2), black); */
    background-color: var(--color-1);
    color: #fff;
    text-align: center;
    line-height: 35px;
    margin-right: 0.5rem;
    transition: 0.3s;
}

.social-icons a:hover {
    background-color: var(--color-2);
    transform: scale(1.05);
}

@media (max-width: 850px) {
    .form {
        grid-template-columns: 1fr;
    }

    .contact-info:before {
        bottom: initial;
        top: -75px;
        right: 65px;
        transform: scale(0.95);
    }

    .contact-form:before {
        top: -13px;
        left: initial;
        right: 70px;
    }

    .text {
        margin: 1rem 0 1.5rem 0;
    }

    .social-media {
        padding: 1.5rem 0 0 0;
    }
}

@media only screen and (max-width:576px) {
    .feedback-container>h1 {
        font-size: 3.5rem;
    }
}

@media (max-width: 480px) {
    .container {
        padding: 1.5rem;
    }

    .contact-info:before {
        display: none;
    }

    form,
    .contact-info {
        padding: 1.7rem 1.6rem;
    }

    .text,
    .information,
    .social-media p {
        font-size: 0.8rem;
    }

    .title {
        font-size: 1.15rem;
    }

    .social-icons a {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }

    .icon {
        width: 23px;
    }

    .input {
        padding: 0.45rem 1.2rem;
    }

    .submit-button {
        padding: 0.45rem 1.2rem;
    }
}