.carousel-div {
    width: 100%;
    height: 80vh;
    position: relative;
}

.carousel-div>img {
    width: 100%;
    height: 100%;
}

.carousel-arrows {
    width: 70px;
    height: 70px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    font-size: 60px;
    position: absolute;
    top: 40%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.left-arrow {
    left: 10px;
}

.right-arrow {
    right: 10px;
}

@media only screen and (max-width:1200px) {
    .carousel-div {
        height: 70vh;
    }
}

@media only screen and (max-width:992px) {
    .carousel-div {
        height: 60vh;
    }
}

@media only screen and (max-width:768px) {
    .carousel-div {
        height: 50vh;
    }
}

@media only screen and (max-width:576px) {
    .carousel-div {
        height: 40vh;
    }

    .carousel-arrows {
        width: 40px;
        height: 40px;
        font-size: 30px;
    }
}