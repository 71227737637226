.main-hero-div {
    width: 100%;
    height: 90vh;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.main-hero-div::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.1);
}

.main-hero-div>img {
    width: 100%;
    height: 100%;
}

.hero-hd {
    position: absolute;
    z-index: 3;
    top: 30%;
    left: 10%;
}

.hero-hd>h1 {
    margin-bottom: 20px;
    line-height: 50px;
}

.width-576-less {
    display: none;
}

@media only screen and (max-width:1200px) {
    .main-hero-div {
        height: 60vh;
    }
}

@media only screen and (max-width:992px) {
    .main-hero-div {
        height: 50vh;
    }
}

@media only screen and (max-width:768px) {
    .main-hero-div {
        height: 40vh;
    }
}

@media only screen and (max-width:576px) {
    .main-hero-div {
        height: 30vh;
    }

    .hero-hd {
        position: absolute;
        z-index: 2;
        top: 30%;
        left: 5%;
    }

    .hero-hd>h1 {
        margin-bottom: 20px;
        line-height: 30px;
        font-size: 24px;
    }

    .width-576-greater {
        display: none;
    }

    .width-576-less {
        display: block;
    }
}