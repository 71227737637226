.top-icon-div {
    width: 50px;
    height: 40px;
    border: 2px solid black;
    position: fixed;
    bottom: 20px;
    right: 10px;
    cursor: pointer;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: red;
    z-index: 1000;
}