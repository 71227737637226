.profile-container {
    width: 100%;
}

.account-details {
    width: 100%;
}

.account-details>div {
    width: 100%;
    display: flex;
}

.account-details>div>div {
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 50%;
    height: 70px;
    padding: 10px;
    display: flex;
    align-items: center;
}

.account-details>div>div>input {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding-left: 10px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.account-details>div>div>input:focus {
    border: none;
    outline: none;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.order-list-img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.modal-main-div {
    width: 100%;
    height: 600px;
    overflow: auto;
}

.modal-order-details-div {
    width: 98%;
    height: auto;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    margin: 20px 0px;
    border-radius: 8px;
    background-color: rgba(136, 120, 55, 0.2);
}

.modal-order-details-div>div {
    padding: 5px;
    width: 50%;
}

.modal-order-details-div>div>p {
    font-size: 20px;
    font-weight: 400;
    color: black;
    padding: 0;
    text-transform: capitalize;
}

.modal-order-products-div {
    height: 300px;
    overflow: auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    text-align: center;
    border-radius: 8px;
    width: 98%;
}

.modal-main-div>div>table>thead {
    height: 50px;
    background-color: rgba(136, 120, 55, 0.2);
}

.modal-main-div>div>table>tbody>tr {
    height: 60px;
}

.modal-main-div>div>table>tbody>tr>td {
    overflow: auto;
}

.modal-main-div>div>table>tbody>tr>td>div {
    height: 50px;
    width: 40px;
    border-radius: 5px;
    margin-right: 5px;
    overflow: hidden;
}

.product-img-div {
    display: flex;
    align-items: center;
}

/* Scroll bar */
.modal-order-products-div::-webkit-scrollbar,
.modal-main-div::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}

.modal-order-products-div::-webkit-scrollbar-thumb,
.modal-main-div::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: grey;
    border-radius: 3px;
}