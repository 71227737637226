.products-div-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 50px;
    height: 120vh;
}

.img-div-container {
    width: 15%;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
}

.img-div-container::-webkit-scrollbar {
    width: 4px;
}

.img-div-container::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 10px;
}

.img-div {
    width: 100%;
    height: 250px;
    margin-bottom: 50px;
    cursor: pointer;
}

.img-div>img {
    width: 100%;
    height: 100%;
}

.main-img-div {
    width: 45%;
    height: 100%;
    overflow: hidden;
}

.product-detail-div {
    width: 35%;
    height: 100%;
    overflow: auto;
}

.product-detail-div::-webkit-scrollbar {
    width: 4px;
}

.product-detail-div::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 10px;
}

.product-detail-div>h1 {
    font-weight: normal;
    text-transform: uppercase;
}

.product-detail-div>p {
    margin-top: 30px;
    text-transform: uppercase;
}

.product-detail-div>h6 {
    color: red;
}

.product-detail-div>h6>span {
    text-decoration: line-through;
    color: rgba(0, 0, 0, 0.7);
}

.product-detail-div>h5 {
    font-weight: normal;
    text-transform: capitalize;
}

.product-detail-div>div>span {
    width: 70px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    transition: all .3s;
    margin-right: 10px;
}

.product-detail-div>div>span:hover {
    background-color: black;
    color: white;
}

.unavailable-size:hover {
    background-color: transparent !important;
    color: black !important;
}

.active-size {
    background-color: black;
    color: white;
}

.product-detail-div>span {
    cursor: pointer;
}

.modal-close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.quanitity-input {
    width: 70px;
    /* height: 50px; */
    border-radius: 5px;
    font-size: 18px;
    padding: 10px;
}

.add-to-cart-btn-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.add-to-cart-btn {
    width: 80%;
}

.final-card-div {
    width: 100%;
    height: 300px;
    display: flex;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 8px;
}

.final-img-div {
    width: 40%;
    height: 100%;
}

.final-img-div>img {
    width: 100%;
    height: 100%;
}

.final-details-div {
    width: 60%;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.product-carousel-div {
    display: none !important;
    margin-bottom: 30px;
}

.product-carousel-slide {
    width: 100%;
    /* height: 800px; */
    max-height: 800px;
}

.product-carousel-slide>img {
    width: 100%;
    /* height: 100%; */
    max-height: 800px;
}

.product-carousel-arrows {
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    font-size: 40px;
    position: absolute;
    top: 50%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media only screen and (max-width:992px) {
    .products-div-container {
        flex-direction: column;
        height: auto;
    }

    .img-div-container {
        display: none;
    }

    .main-img-div {
        display: none;
    }

    .product-carousel-div {
        display: block !important;
    }

    .product-detail-div {
        width: 100%;
    }
}

@media only screen and (max-width:576px) {
    .product-carousel-slide {
        /* height: 600px; */
        max-height: 600px;
    }

    .final-card-div {
        height: 220px;
    }
}