.login-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
}

.login-div {
    width: 400px;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
    position: relative;
    overflow: hidden;
}

.login-div::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(136, 120, 55, 0.5);
    z-index: -999;
    transform: rotateZ(25deg);
    border-radius: 50%;
}

.login-div>div {
    width: 100%;
}

.login-div>span {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    font-weight: 600;
}

.login-div>span>span {
    color: red;
}

.password-div {
    position: relative;
}

.password-show {
    position: absolute;
    right: 10px;
    top: 20%;
    font-size: 24px;
    cursor: pointer;
}

.login-div>div>input,
.forgot-main-div>input {
    width: 100%;
    height: 60px;
    border-radius: 5px;
    padding-left: 10px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.login-div>div>input:focus,
.forgot-main-div>input:focus {
    border: none;
    outline: none;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.login-div>button {
    margin-top: 20px;
    width: 100%;
}

.login-div>p {
    margin-top: 10px;
    width: 100%;
}