.main-about-page-div {
    width: 100%;
    padding-top: 100px;
    color: black;
}

.content-body>h1 {
    text-align: center;
    margin-bottom: 100px;
    text-transform: uppercase;
}

.content-body>h2 {
    margin: 50px 0px;
    text-transform: uppercase;
    font-weight: normal;
}

.content-body>p,
.content-body>ul>li {
    line-height: 35px;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 300;
}

@media only screen and (max-width:576px) {
    .content-body>h1 {
        margin-bottom: 50px;
    }

    .content-body>p,
    .content-body>ul>li {
        font-size: 18px;
    }
}