.main-about-div {
    width: 100%;
    height: 80vh;
    position: relative;
    background-image: url("../../Assets/About/about-banner.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.main-about-div::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: rgb(0, 0, 0, 0.4);
}

.about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
    position: relative;
}

.about-div {
    position: absolute;
    z-index: 2;
    color: white;
    width: 100%;
}

.about-div>p {
    font-size: 20px;
    text-align: center;
}

.about-div>h1 {
    text-align: center;
}


@media only screen and (max-width:1200px) {
    .main-about-div {
        height: 70vh;
    }
}

@media only screen and (max-width:992px) {
    .main-about-div {
        height: 60vh;
    }
}

@media only screen and (max-width:768px) {
    .main-about-div {
        height: 50vh;
    }
}

@media only screen and (max-width:576px) {
    .main-about-div {
        height: 50vh;
    }
}