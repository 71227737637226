.contact-detials {
    display: flex;
    height: 50px;
}

.contact-detials>p {
    width: 85%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
}

.contact-detials>span {
    width: 15%;
}

.shipping-details {
    color: rgba(0, 0, 0, 0.7) !important;
    text-decoration: none !important;
    cursor: default !important;
}

.shipping-hd {
    margin-top: 30px;
    color: rgba(0, 0, 0, 0.7);
}

.shipping-method {
    height: 60px;
    border: 2px solid rgb(95, 195, 235);
    border-radius: 5px;
    display: flex;
    justify-content: space-between !important;
    background-color: rgba(135, 206, 235, 0.2);
}

.shipping-method>p {
    margin: 0;
    padding: 0;
    font-weight: 500;
}